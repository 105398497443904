import React from 'react'
import GeneralInfoForm from "./forms/GeneralInfoForm";

class General extends React.Component {

    render() {
        return(
            <div className="row">
                <div className="col-12">
                    <h2>General Information</h2>

                    <GeneralInfoForm />
                </div>
            </div>
        );
    }

}

export default General