import React from 'react'
import OutOfOfficeForm from "./forms/OutOfOfficeForm";

class OutOfOffice extends React.Component {

    render() {
        return(
            <div className="row">
                <div className="col-12">
                    <h2>Out of Office</h2>

                    <OutOfOfficeForm />

                </div>
            </div>
        );
    }

}

export default OutOfOffice