import {Link} from 'react-router-dom'

function NavBar() {

    return (
        <div>
            <div className="row mt-3 mb-3">
                <div className="col-12">
                    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
			<span className="d-md-none">
				<a className="navbar-brand navbar-logo" href="/"> <img alt="Logo" src={process.env.PUBLIC_URL + '/images/nav-brand-logo.png'}/></a>
			</span>
                        <button className="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"/>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">

                                <li className="nav-item">
                                    <Link className="nav-link" to="/"><i className="fas mr-2 fa-home"/>Home</Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" to="/ooo"><i className="fas mr-2 fa-calendar-alt"/>Out of Office</Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" to="/general"><i className="fas mr-2 fa-edit"/>General Information</Link>
                                </li>

                            </ul>
                            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link" href="mailto:info@freedomvalleycamping.com"><i
                                        className="fas fa-envelope"/></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="tel:4199298100"><i className="fas fa-phone"/></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link"
                                       href="https://www.facebook.com/pages/Freedom-Valley-Campground/100592406660880?ref=ts"
                                       rel="noreferrer"
                                       target="_blank"><i className="fab fa-facebook-square"/></a>
                                </li>
                            </ul>

                        </div>
                    </nav>
                </div>
            </div>

        </div>
    );
}

export default NavBar