// NOTE:  Subtract 1 from the month to agree with the constructor param requirementsL
export const firstCheckInDate = new Date(2021, 3, 30);
export const firstCheckOutDate = new Date(2021, 4, 1);
export const lastCheckInDate = new Date(2021, 9, 9);
export const lastCheckOutDate = new Date(2021, 9, 10);


export const dateFromString = (value) => {
    let returnValue = new Date(value);
    // console.log("Test date: ", new Date(value), " valid? ", returnValue.toDateString() !== "Invalid Date");
    if (returnValue.toDateString() !== "Invalid Date" && returnValue.toISOString().slice(0,10) === value.slice(0,10)) {
        return returnValue;
    }
    return null;
}

export const minDateOf = (date1, date2) => {
    if (date1.getTime() < date2.getTime()) {
        return date1;
    } else {
        return date2;
    }
}

export const maxDateOf = (date1, date2) => {
    if (date1.getTime() >= date2.getTime()) {
        return date1;
    } else {
        return date2;
    }
}

Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export const minCheckInDate = () => {
    return maxDateOf(firstCheckInDate, new Date());
}
