import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getValidationErrors} from './FormValidation'
import CONFIG from "../ApiConfig";
import MarkdownIt from "markdown-it";
import {withOktaAuth} from "@okta/okta-react";

const rules = {
    Details: {
        label: "Details",
        required: true,
    },
    StartDate: {
        label: "Start Date date",
        type: "Date",
        required: true,
    },
    EndDate: {
        label: "End Date date",
        type: "Date",
        required: true,
    },
}

export default withOktaAuth(class GeneralInfoForm extends React.Component {
    constructor(props) {
        super(props);
        this.md = new MarkdownIt();
        this.md.set({ breaks: true })

        this.state = this.clearState();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setWebsiteOpenOnDate = this.setWebsiteOpenOnDate.bind(this);
        this.setFirstCheckinDate = this.setFirstCheckinDate.bind(this);
        this.setLastCheckoutDate = this.setLastCheckoutDate.bind(this);
    }

    async loadOutOfOffice() {
        fetch(CONFIG.API_HOST + '/season')
            .then(response => response.json())
            .then(data => {
                this.setState({
                    isLoaded: true,
                });
                // console.log('Success:', data);
                if (typeof data.OpenWelcomeMessage !== "undefined") {
                    let season = this.state.season;
                    season.OpenWelcomeMessage = data.OpenWelcomeMessage;
                    season.ClosedThankYouMessage = data.ClosedThankYouMessage;
                    season.OfficeHours = data.OfficeHours;
                    season.Announcements = data.Announcements;
                    season.WebsiteOpenOn = new Date(data.WebsiteOpenOn);
                    season.FirstCheckinDate = new Date(data.FirstCheckinDate);
                    season.LastCheckoutDate = new Date(data.LastCheckoutDate);
                    this.setState({season: season});
                }
            })
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        if (!this.state.isLoaded) {
            this.loadOutOfOffice();
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        let season = this.state.season;
        season[name] = value;
        this.setState({season: season});
    }

    handleOnBlur(event) {
        // console.log("onBlur: ", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        this.validateField(name, value);
    }


    validateField(name, value) {
        let ruleset = rules[name];

        // console.log("validateField(name: ", name, ", value: ", value, ") ruleset: ", JSON.stringify(ruleset));

        if (typeof ruleset === 'object') {
            let validationErrors = this.state.fieldErrors;
            let result = getValidationErrors(ruleset, value);
            result.length === 0 ? validationErrors.delete(name) : validationErrors.set(name, result);
            this.setState({fieldErrors: validationErrors})
            return result;
        }
        return "";
    }

    clearState() {
        return {
            error: null,
            isLoaded: false,
            alertClass: "alert p-3 mb-2 bg-light text-dark",
            statusMessage: "",
            iconClass: "",
            season: {
                WebsiteOpenOn: "",
                FirstCheckinDate: "",
                LastCheckoutDate: "",
                ShowUpcomingEvents: "",
                OpenWelcomeMessage: "",
                ClosedThankYouMessage: "",
                OfficeHours: "",
                Announcements: "",
            },
            fieldErrors: new Map(),
        };
    }

    getRawMarkup() {
        return { __html: this.md.render(this.state.season.Announcements) };
    }

    setWebsiteOpenOnDate(date) {
        let result = this.validateField("WebsiteOpenOn", date);
        // console.log(result, date)
        if (result.length === 0) {
            let season = this.state.season;
            season.WebsiteOpenOn = date
            this.setState({season: season});
        }
    }

    setFirstCheckinDate(date) {
        let result = this.validateField("FirstCheckinDate", date);
        // console.log(result, date)
        if (result.length === 0) {
            this.setState({minLastCheckoutDate: date.addDays(1)});
            let season = this.state.season;
            season.FirstCheckinDate = date
            this.setState({season: season});
        }
    }

    setLastCheckoutDate(date) {
        let result = this.validateField("LastCheckoutDate", date);
        // console.log(result, date)
        if (result.length === 0) {
            let season = this.state.season;
            season.LastCheckoutDate = date
            this.setState({season: season});
        }
    }

    setStatusText(status, message = "") {
        switch (status) {
            case 0:
                this.setState({alertClass: "alert p-3 mb-2 bg-light text-dark"});
                this.setState({statusMessage: "Submitting your request ..."});
                this.setState({iconClass: "fas fa-exchange-alt"});
                break;
            case 204:
                this.setState({alertClass: "alert p-3 mb-2 bg-success text-dark"});
                this.setState({statusMessage: "Changes saved."})
                this.setState({iconClass: "fas fa-check-circle"});
                break;
            case 400:
                this.setState({alertClass: "alert p-3 mb-2 bg-warning text-dark"})
                this.setState({statusMessage: "Please correct the items highlighted in the form"});
                this.setState({iconClass: "fas fa-exclamation-circle"})
                break;
            default:
                this.setState({alertClass: "alert p-3 mb-2 bg-danger"})
                this.setState({statusMessage: message})
                this.setState({iconClass: "fas fa-exclamation-triangle"})
                break;
        }
    }

    async submitRegistration(body) {

        try {
            this.setStatusText(0);
            const response = await fetch(CONFIG.API_HOST + '/season', {
                headers: {
                    Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                    'Content-Type': 'application/json',
                },
                method: 'put',
                body,
            });
            await response;
            if (response.status === 204) {
                this.setStatusText(response.status);
            } else if (response.status === 400) {
                this.setStatusText(response.status);
                let serverErrors = await response.json();
                if (typeof serverErrors.fieldErrors === 'object') {
                    this.handleServerErrors(serverErrors.fieldErrors);
                }
            } else {
                let errorMessage = "Unexpected status: " + response.status
                this.setStatusText(response.status, errorMessage);
            }
        } catch (e) {
            this.setStatusText(500, e.toString());
        }
    }

    handleServerErrors(serverErrors) {
        let validationErrors = new Map(Object.entries(serverErrors))
        // console.log("server errors: ", validationErrors)
        this.setState({fieldErrors: validationErrors})
    }


    handleSubmit(event) {
        this.setState({fieldErrors: new Map()})
        this.validateField("OpenWelcomeMessage", this.state.season.OpenWelcomeMessage);
        this.validateField("FirstCheckinDate", this.state.season.FirstCheckinDate);
        this.validateField("LastCheckoutDate", this.state.season.LastCheckoutDate);
        if (this.state.fieldErrors.size === 0) {
            this.submitRegistration(JSON.stringify(this.state.season));
        }
        event.preventDefault();

    }

    render() {
        const {error, isLoaded} = this.state;
        if (error) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="p-3 mb-2 bg-danger" role="alert">
                            <i className="fas fa-exclamation-triangle"/> ERROR: {error.message}
                        </div>
                    </div>
                </div>
            );
        } else if (!isLoaded) {
            return (

                <div className="row">
                    <div className="col-12">
                        <div className="p-3 mb-2 bg-secondary text-black" role="alert">
                            <i className="fas fa-spinner"/> Loading content ....
                        </div>
                    </div>
                </div>
            );
        } else {

            return (
                <div id="seasonFormTop" className="SeassonForm">

                    {this.state.statusMessage &&
                    <div className="row">
                        <div className="col-12">
                            <div className={this.state.alertClass} role="alert">
                                <i className={this.state.iconClass}/> {this.state.statusMessage}
                            </div>
                        </div>
                    </div>
                    }

                    <p className="text-warning"><i className="fas fa-asterisk mr-2"/><strong>Denotes required
                        field</strong>
                    </p>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row mb-3">
                            <label htmlFor="WebsiteOpenOn" className="col-sm-4 col-form-label"><i
                                className="fas fa-asterisk mr-2"/>Phone lines open on:</label>
                            <div className="col-sm-8">
                                <DatePicker id="WebsiteOpenOn"
                                            className={this.state.fieldErrors.has('WebsiteOpenOn') ? 'form-control is-invalid' : 'form-control'}
                                            selected={this.state.season.WebsiteOpenOn}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            onChange={date => this.setWebsiteOpenOnDate(date)}/>
                                {this.state.fieldErrors.has('WebsiteOpenOn') &&
                                <div className="error-message">
                                    {this.state.fieldErrors.get('WebsiteOpenOn')}
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="FirstCheckinDate" className="col-sm-4 col-form-label"><i
                                className="fas fa-asterisk mr-2"/>First check in:</label>
                            <div className="col-sm-8">
                                <DatePicker id="FirstCheckinDate"
                                            className={this.state.fieldErrors.has('FirstCheckinDate') ? 'form-control is-invalid' : 'form-control'}
                                            selected={this.state.season.FirstCheckinDate}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            onChange={date => this.setFirstCheckinDate(date)}/>
                                {this.state.fieldErrors.has('FirstCheckinDate') &&
                                <div className="error-message">
                                    {this.state.fieldErrors.get('FirstCheckinDate')}
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="LastCheckoutDate" className="col-sm-4 col-form-label"><i
                                className="fas fa-asterisk mr-2"/>Last check in:</label>
                            <div className="col-sm-8">
                                <DatePicker id="LastCheckoutDate"
                                            className={this.state.fieldErrors.has('LastCheckoutDate') ? 'form-control is-invalid' : 'form-control'}
                                            selected={this.state.season.LastCheckoutDate}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            onChange={date => this.setLastCheckoutDate(date)}/>
                                {this.state.fieldErrors.has('LastCheckoutDate') &&
                                <div className="error-message">
                                    {this.state.fieldErrors.get('LastCheckoutDate')}
                                </div>
                                }
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="OpenWelcomeMessage" className="col-sm-4 col-form-label"><i
                                className="fas fa-asterisk mr-2"/>Open welcome message:</label>
                            <div className="col-sm-8">
                        <textarea rows="6"
                                  className={this.state.fieldErrors.has('OpenWelcomeMessage') ? 'form-control is-invalid' : 'form-control'}
                                  id="OpenWelcomeMessage" value={this.state.season.OpenWelcomeMessage}
                                  placeholder="Message to display when open"
                                  onBlur={this.handleOnBlur}
                                  onChange={this.handleInputChange}/>
                                <div className="invalid-feedback">
                                    {this.state.fieldErrors.has('OpenWelcomeMessage') ? this.state.fieldErrors.get('OpenWelcomeMessage') : 'Please provide a valid message'}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="ClosedThankYouMessage" className="col-sm-4 col-form-label"><i
                                className="fas fa-asterisk mr-2"/>Closed thank you message:</label>
                            <div className="col-sm-8">
                        <textarea rows="6"
                                  className={this.state.fieldErrors.has('ClosedThankYouMessage') ? 'form-control is-invalid' : 'form-control'}
                                  id="ClosedThankYouMessage" value={this.state.season.ClosedThankYouMessage}
                                  placeholder="Message to display when closed"
                                  onBlur={this.handleOnBlur}
                                  onChange={this.handleInputChange}/>
                                <div className="invalid-feedback">
                                    {this.state.fieldErrors.has('ClosedThankYouMessage') ? this.state.fieldErrors.get('ClosedThankYouMessage') : 'Please provide a valid message'}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="Announcements" className="col-sm-4 col-form-label">Announcements:</label>
                            <div className="col-sm-8">

                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a className="nav-item nav-link active" id="nav-markdown-tab" data-toggle="tab"
                                           href="#nav-markdown" role="tab" aria-controls="nav-markdown"
                                           aria-selected="true">Write</a>
                                        <a className="nav-item nav-link" id="nav-preview-tab" data-toggle="tab"
                                           href="#nav-preview" role="tab" aria-controls="nav-preview"
                                           aria-selected="false">Preview</a>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane show active" id="nav-markdown" role="tabpanel"
                                         aria-labelledby="nav-markdown-tab">
                                <textarea rows="6"
                                          className={this.state.fieldErrors.has('Announcements') ? 'form-control is-invalid' : 'form-control'}
                                          id="Announcements" value={this.state.season.Announcements}
                                          placeholder="Special announcements (or blank for none)"
                                          onBlur={this.handleOnBlur}
                                          onChange={this.handleInputChange}/>
                                        <div className="alert bg-secondary text-light" role="alert"><i className="fab fa-markdown"/> Styling with <a href="https://guides.github.com/features/mastering-markdown/" rel="noreferrer" target="_blank">Markdown</a> is supported.</div>
                                    </div>
                                    <div className="tab-pane" id="nav-preview" role="tabpanel"
                                         aria-labelledby="nav-preview-tab">
                                        <span dangerouslySetInnerHTML={this.getRawMarkup()} />
                                    </div>
                                </div>

                                <div className="invalid-feedback">
                                    {this.state.fieldErrors.has('Announcements') ? this.state.fieldErrors.get('Announcements') : 'Please provide a valid message'}
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="OfficeHours" className="col-sm-4 col-form-label"><i
                                className="fas fa-asterisk mr-2"/>Office hours:</label>
                            <div className="col-sm-8">
                        <textarea rows="3"
                                  className={this.state.fieldErrors.has('OfficeHours') ? 'form-control is-invalid' : 'form-control'}
                                  id="OfficeHours" value={this.state.season.OfficeHours}
                                  placeholder="Office hours (hidden when closed)"
                                  onBlur={this.handleOnBlur}
                                  onChange={this.handleInputChange}/>
                                <div className="invalid-feedback">
                                    {this.state.fieldErrors.has('OfficeHours') ? this.state.fieldErrors.get('OfficeHours') : 'Please provide a valid message'}
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="mb-5 mr-2 btn btn-primary">Save Changes</button>
                    </form>
                </div>
            )
        }

    }
}
);
