function Header() {
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div id="siteBanner" role="banner">
                          <span className="d-none d-md-block">
                              <img className="img-fluid w-100" src={process.env.PUBLIC_URL + '/images/LakeBanner-black-01.jpg'}
                                   alt="Freedom Valley Campground"/>
                          </span>
                    </div>
                </div>
            </div>

            <noscript>
                <div className="row">
                    <div className="col-12">
                        <p className="alert alert-danger">JavaScript is off in your browser. Our site works best with it
                            enabled.</p>
                    </div>
                </div>
            </noscript>
        </div>
    );
}

export default Header;
