import { dateFromString} from "./DateUtils";

export const getValidationErrors = (rules, value) => {
    let label = typeof rules.label === 'string' ? rules.label : 'Value';
    let errorList = "";
    // console.log("rules:", rules, "value:", value, "label:", label);
    if (typeof rules.required === 'boolean' && rules.required && value.length === 0) {
        errorList = errorList.concat(label, " is required");
    } else {
        if (typeof rules.min === 'number' && rules.min > 0 && value.length < rules.min) {
            errorList = errorList.concat(label, " must be at least " + rules.min + " characters");
        }

        if (typeof rules.max === 'number' && rules.max > 0 && value.length > rules.max) {
            errorList = errorList.concat(label, " cannot exceed " + rules.max + " characters");
        }

        if (typeof rules.regex !== 'undefined' && !rules.regex.test(value)) {
            errorList = errorList.concat(label, " is not valid");
        }

        if (typeof rules.type !== 'undefined' && rules.type === "Date") {
            let parsedDate
            if (value instanceof Date)
            {
                parsedDate = value;
            } else {
                parsedDate = dateFromString(value);
            }
            if (parsedDate === null) {
                errorList = errorList.concat(label, " is not valid");
            } else if (typeof rules.min === 'string' && parsedDate.getTime() < dateFromString(rules.min).getTime()) {
                errorList = errorList.concat(dateFromString(rules.min).toDateString(), " is the earliest ", label, " allowed");
            } else if (typeof rules.min === 'object' && parsedDate.getTime() < rules.min.getTime()) {
                errorList = errorList.concat(rules.min.toString().substr(0,15), " is the earliest ", label, " allowed");
            } else if (typeof rules.max === 'string' && parsedDate.getTime() > dateFromString(rules.max).getTime()) {
                errorList = errorList.concat(dateFromString(rules.max).toDateString(), " is the latest ", label, " allowed");
            } else if (typeof rules.max === 'object' && parsedDate.getTime() > rules.max.getTime()) {
                errorList = errorList.concat(rules.max.toString().substr(0,15), " is the latest ", label, " allowed");
            }
        }
    }

    return errorList;
};