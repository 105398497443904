
function Footer() {

    const copyDate = new Date().getFullYear()

    return (
        <div>

            <div className="row">
                <div className="col-12"><img className="img-fluid w-100 mt-3" src={process.env.PUBLIC_URL + '/images/rainbow-bar-black.jpg'}
                                             alt="Rainbow colored horizontal rule"/>
                    <p> &copy; {copyDate} Freedom Valley Campground. All rights reserved. </p>
                </div>
            </div>

        </div>
    );
}

export default Footer