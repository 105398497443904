import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {LoginCallback, SecureRoute, Security} from '@okta/okta-react';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import Home from './Home';
import Login from './Login';
import {oktaAuthConfig, oktaSignInConfig} from './config';
import Header from "./Header";
import NavBar from "./NavBar";
import OutOfOffice from "./OutOfOffice";
import General from "./General";
import Footer from "./Footer";
import ErrorPage from "./ErrorPage";

const oktaAuth = new OktaAuth(oktaAuthConfig);

const AppWithRouterAccess = () => {
    const history = useHistory();

    const customAuthHandler = () => {
        history.push('/login');
    };

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (

        <div>

            <div className="container">

                <Header/>

                    <NavBar/>

                    <h1 className="mb-3">Website Administration Control Panel</h1>

                    <Security
                        oktaAuth={oktaAuth}
                        onAuthRequired={customAuthHandler}
                        restoreOriginalUri={restoreOriginalUri}
                    >
                        <Switch>
                            <Route path='/' exact={true} component={Home}/>
                            <SecureRoute path='/ooo' component={OutOfOffice}/>
                            <SecureRoute path='/general' component={General}/>
                            <Route path='/login' render={() => <Login config={oktaSignInConfig}/>}/>
                            <Route path='/login/callback' component={LoginCallback}/>
                            <Route path="*" component={ErrorPage}/>
                        </Switch>
                    </Security>

                <Footer/>

            </div>

        </div>


    );
};
export default AppWithRouterAccess;
