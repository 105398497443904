import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    getValidationErrors,
} from "./FormValidation";
import CONFIG from "../ApiConfig";
import { withOktaAuth } from '@okta/okta-react';

const rules = {
    Details: {
        label: "Details",
        required: true,
    },
    StartDate: {
        label: "Start Date date",
        type: "Date",
        required: true,
    },
    EndDate: {
        label: "End Date date",
        type: "Date",
        required: true,
    },
}

export default withOktaAuth(class OutOfOfficeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.clearState();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
    }

    async loadOutOfOffice() {
        fetch(CONFIG.API_HOST + '/events/ooo')
            .then(response => {
                if (response.ok) {
                    return response.json()
                }})
            .then(data => {
                if (typeof data?.Details !== "undefined") {
                    this.setState({
                        okToDelete: true,
                    });
                    let ooo = this.state.ooo;
                    ooo.Details = data.Details;
                    ooo.StartDate = new Date(data.StartDate);
                    ooo.EndDate = new Date(data.EndDate);
                    this.setState({ooo: ooo});
                }
                this.setState({
                    isLoaded: true,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        if (!this.state.isLoaded) {
            this.loadOutOfOffice();
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        let ooo = this.state.ooo;
        ooo[name] = value;
        this.setState({ooo: ooo});
    }

    handleOnBlur(event) {
        // console.log("onBlur: ", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        this.validateField(name, value);
    }


    validateField(name, value) {
        let ruleset = rules[name];

        // console.log("validateField(name: ", name, ", value: ", value, ") ruleset: ", JSON.stringify(ruleset));

        if (typeof ruleset === 'object') {
            let validationErrors = this.state.fieldErrors;
            let result = getValidationErrors(ruleset, value);
            result.length === 0 ? validationErrors.delete(name) : validationErrors.set(name, result);
            this.setState({fieldErrors: validationErrors})
            return result;
        }
        return "";
    }

    clearState() {
        return {
            error: null,
            isLoaded: false,
            okToDelete: false,
            alertClass: "alert p-3 mb-2 bg-light text-dark",
            statusMessage: "",
            iconClass: "",
            ooo: {
                StartDate: "",
                EndDate: "",
                Details: "",
            },
            fieldErrors: new Map(),
        };
    }

    setStartDate(date) {
        let result = this.validateField("StartDate", date);
        // console.log(result, date)
        if (result.length === 0) {
            this.setState({minEndDate: date.addDays(1)});
            let ooo = this.state.ooo;
            ooo.StartDate = date
            this.setState({ooo: ooo});
        }
    }

    setEndDate(date) {
        let result = this.validateField("EndDate", date);
        // console.log(result, date)
        if (result.length === 0) {
            let ooo = this.state.ooo;
            ooo.EndDate = date
            this.setState({ooo: ooo});
        }
    }

    setStatusText(status, message = "") {
        switch (status) {
            case 0:
                this.setState({alertClass: "alert p-3 mb-2 bg-light text-dark"});
                this.setState({statusMessage: "Submitting your request ..."});
                this.setState({iconClass: "fas fa-exchange-alt"});
                break;
            case 204:
                this.setState({alertClass: "alert p-3 mb-2 bg-success text-dark"});
                this.setState({statusMessage: "Changes saved."})
                this.setState({iconClass: "fas fa-check-circle"});
                break;
            case 400:
                this.setState({alertClass: "alert p-3 mb-2 bg-warning text-dark"})
                this.setState({statusMessage: "Please correct the items highlighted in the form"});
                this.setState({iconClass: "fas fa-exclamation-circle"})
                break;
            default:
                this.setState({alertClass: "alert p-3 mb-2 bg-danger"})
                this.setState({statusMessage: message})
                this.setState({iconClass: "fas fa-exclamation-triangle"})
                break;
        }
    }

    async handleDelete() {

        try {
            this.setStatusText(0);
            const response = await fetch(CONFIG.API_HOST + '/events/ooo', {
                headers: {
                    Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                },
                method: 'delete'
            });
            await response;
            if (response.status === 204) {
                this.setState({
                    okToDelete: false,
                });
                this.setState({
                    ooo: {
                        StartDate: "",
                        EndDate: "",
                        Details: "",
                    }
                });
                this.setStatusText(response.status);
            } else {
                let errorMessage = "Unexpected status: " + response.status
                this.setStatusText(response.status, errorMessage);
            }
        } catch (e) {
            this.setStatusText(500, e.toString());
        }
    }

    async submitRegistration(body) {

        // console.log("Access token" + JSON.stringify(this.props.authState.accessToken))

        try {
            this.setStatusText(0);
            const response = await fetch(CONFIG.API_HOST + '/events/ooo', {
                headers: {
                    Authorization: 'Bearer ' + this.props.authState.accessToken.accessToken,
                    'Content-Type': 'application/json',
                },
                method: 'put',
                body,
            });
            await response;
            if (response.status === 204) {
                this.setStatusText(response.status);
                this.setState({
                    okToDelete: true,
                });
            } else if (response.status === 400) {
                this.setStatusText(response.status);
                let serverErrors = await response.json();
                if (typeof serverErrors.fieldErrors === 'object') {
                    this.handleServerErrors(serverErrors.fieldErrors);
                }
            } else {
                let errorMessage = "Unexpected status: " + response.status
                this.setStatusText(response.status, errorMessage);
            }
        } catch (e) {
            this.setStatusText(500, e.toString());
        }
    }

    handleServerErrors(serverErrors) {
        let validationErrors = new Map(Object.entries(serverErrors))
        // console.log("server errors: ", validationErrors)
        this.setState({fieldErrors: validationErrors})
    }


    handleSubmit(event) {
        this.setState({fieldErrors: new Map()})
        this.validateField("Details", this.state.ooo.Details);
        this.validateField("StartDate", this.state.ooo.StartDate);
        this.validateField("EndDate", this.state.ooo.EndDate);
        if (this.state.fieldErrors.size === 0) {
            this.submitRegistration(JSON.stringify(this.state.ooo));
        }
        event.preventDefault();

    }

    render() {
        const {error, isLoaded} = this.state;
        if (error) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="p-3 mb-2 bg-danger" role="alert">
                            <i className="fas fa-exclamation-triangle"/> ERROR: {error.message}
                        </div>
                    </div>
                </div>
            );
        } else if (!isLoaded) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="p-3 mb-2 bg-secondary text-black" role="alert">
                            <i className="fas fa-spinner"/> Loading content ....
                        </div>
                    </div>
                </div>
            );
        } else {

            return (
                <div id="oooFormTop" className="OooForm">

                    {this.state.statusMessage &&
                    <div className="row">
                        <div className="col-12">
                            <div className={this.state.alertClass} role="alert">
                                <i className={this.state.iconClass}/> {this.state.statusMessage}
                            </div>
                        </div>
                    </div>
                    }

                    <p className="text-warning"><i className="fas fa-asterisk mr-2"/><strong>Denotes required
                        field</strong>
                    </p>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row mb-3">
                            <label htmlFor="StartDate" className="col-sm-4 col-form-label"><i
                                className="fas fa-asterisk mr-2"/>Starting:</label>
                            <div className="col-sm-8">
                                <DatePicker id="StartDate"
                                            className={this.state.fieldErrors.has('StartDate') ? 'form-control is-invalid' : 'form-control'}
                                            selected={this.state.ooo.StartDate}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            onChange={date => this.setStartDate(date)}/>
                                {this.state.fieldErrors.has('StartDate') &&
                                <div className="error-message">
                                    {this.state.fieldErrors.get('StartDate')}
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="EndDate" className="col-sm-4 col-form-label"><i
                                className="fas fa-asterisk mr-2"/>Ending:</label>
                            <div className="col-sm-8">
                                <DatePicker id="EndDate"
                                            className={this.state.fieldErrors.has('EndDate') ? 'form-control is-invalid' : 'form-control'}
                                            selected={this.state.ooo.EndDate}
                                            showTimeSelect
                                            dateFormat="Pp" s
                                            onChange={date => this.setEndDate(date)}/>
                                {this.state.fieldErrors.has('EndDate') &&
                                <div className="error-message">
                                    {this.state.fieldErrors.get('EndDate')}
                                </div>
                                }
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label htmlFor="Details" className="col-sm-4 col-form-label"><i
                                className="fas fa-asterisk mr-2"/>Message:</label>
                            <div className="col-sm-8">
                        <textarea rows="3"
                                  className={this.state.fieldErrors.has('Details') ? 'form-control is-invalid' : 'form-control'}
                                  id="Details" value={this.state.ooo.Details}
                                  placeholder="Out of office message"
                                  onBlur={this.handleOnBlur}
                                  onChange={this.handleInputChange}/>
                                <div className="invalid-feedback">
                                    {this.state.fieldErrors.has('Details') ? this.state.fieldErrors.get('Details') : 'Please provide a valid message'}
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="mb-5 mr-2 btn btn-primary">Save Changes</button>
                        {this.state.okToDelete &&
                        <button type="button" onClick={this.handleDelete}
                                className='mb-5 btn btn-danger'>Delete
                            Out Of Office</button>
                        }
                    </form>
                </div>
            )
        }

    }
});
