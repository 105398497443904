import React from 'react';

const ErrorPage = () => {

    return (
        <div>
            <div className="row mt-5">
                <div className="col-12">
                    <div className="jumbotron jumbotron-fluid bg-primary">
                        <div className="container">
                            <h1 className="display-4">Page not found</h1>
                            <p className="lead">Please choose from a link on the menu above.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;
